"use client";

import Link from "next/link";
import { useEffect } from "react";
import { ErrorState } from "~/components/errors/ErrorState";
import { Button } from "~/components/ui/Button";
import { useSupportChat } from "~/contexts/ChatSupportContext";
import { useCommonDict } from "~/hooks/useTranslations";
import { getLogOutUrl } from "~/utils/url";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  const t = useCommonDict();
  const { openChat } = useSupportChat();

  return (
    <div className="align-center flex h-full w-full flex-col items-center justify-center p-4">
      <div className="flex flex-col items-center justify-center gap-5">
        <ErrorState message={"Oops! Something went wrong... 😔"} />

        <Button size="lg" variant="outline" onClick={() => openChat()}>
          Open chat
        </Button>
      </div>

      <div className="mt-4">
        <Link href={getLogOutUrl()} prefetch={false} legacyBehavior>
          <Button variant="ghost">Log out</Button>
        </Link>
      </div>
    </div>
  );
}
